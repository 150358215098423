<template>
  <div>
    <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
      <b-row class="form-row">
        <b-col md="8">
          <b-card header="Informações básicas" class="mb-1">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>

                    <b-form-input
                      v-model="item.title"
                      placeholder="Título do produto"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    class="mt-1"
                    :class="{ 'is-invalid': $v.item.description.$error }"
                  >
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Descrição
                    </label>
                    <editor
                      class="about-editor-1"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.description"
                      :init="
                        this.optionEditor({
                          height: 600,
                          selector: 'about-editor-1',
                        })
                      "
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group class="mb-0 mt-1">
                    <label for=""
                      >Sobre o produto -
                      <small>Será exibido no final da página.</small></label
                    >
                    <editor
                      class="about-editor-2"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.about"
                      :init="
                        this.optionEditor({
                          height: 300,
                          selector: 'about-editor-2',
                        })
                      "
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card header="Sales Force" header-class="mb-1" class="mb-1">
            <b-card-text>
              <div class="form-group mb-2">
                <b-form-checkbox @change="changeSalesForcePosition" v-model="item.sales_force_positions"
                  class="custom-control-primary">
                  Exibir cargos (Sales Force)
                </b-form-checkbox>
              </div>

              <div class="form-group mb-0" v-if="item.sales_force_positions">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Cargos
                </label>
                <v-select class="v-select-tags" label="title" item-text="title" item-value="code"
                  v-model="selectPosition" placeholder="Digite o título" :options="optionsSalesForcePositions"
                  @search="fetchSalesForcePositions" @input="positionSelected" :class="{ 'is-invalid': $v.item.positions.$error }">
                  <span slot="no-options">
                    Nenhum registro encontrado
                  </span>
                </v-select>

                <draggable v-if="item.positions" tag="ul" :list="item.positions" class="list-group mt-1"
                  handle=".handle">
                  <li v-for="(element, idx) in item.positions" :key="element.code" class="list-group-item">
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected">{{ element.title }}</span>

                    <feather-icon icon="XCircleIcon" class="float-right" size="20" @click="removeAtPosition(idx)" />
                  </li>
                </draggable>

                <p class="mt-1 mb-0">Os cargos serão listados no formulário de interesse, na página do concurso.</p>
              </div>

              <div v-else class="form-group mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Sales force
                </label>
                <v-select ref="selectSalesForce" class="text-elipse-300" label="title" item-text="title" item-value="code"
                  v-model="item.salesforce" placeholder="Digite o título" :options="optionsSalesForce"
                  @search="fetchSalesForce" :class="{ 'is-invalid': $v.item.salesforce.$error }">
                  <span slot="no-options" @click="$refs.selectSalesForce.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>

            </b-card-text>
          </b-card>

          <b-card
            header="Produtos relacionados"
            header-class="mb-1"
            class="mb-1"
          >
            <b-tabs>
              <b-tab title="Curso Online" @click="changeProductType(2)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.online"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.online"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(2, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
              <b-tab title="Curso Presencial" @click="changeProductType(1)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.presencial"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.presencial"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(1, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
              <b-tab title="Live" @click="changeProductType(3)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.live"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.live"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(3, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
              <b-tab title="Apostilas" @click="changeProductType(6)">
                <div class="form-row">
                  <div class="col-md-9">
                    <p class="mb-0 pt-1">
                      Você pode ordenar arrastando os produtos listados abaixo.
                    </p>
                  </div>
                  <div class="col-md-3">
                    <b-button
                      v-b-modal.modal-product
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      block
                      variant="flat-primary"
                    >
                      <feather-icon icon="PlusCircleIcon" /> adicionar
                    </b-button>
                  </div>
                </div>
                <draggable
                  tag="ul"
                  :list="this.item.products.book"
                  class="list-group mt-1"
                  handle=".handle"
                >
                  <li
                    v-for="(element, index) in this.item.products.book"
                    :key="index"
                    class="list-group-item"
                  >
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>
                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right"
                      size="20"
                      @click="removeAt(6, index)"
                    />
                  </li>
                </draggable>
              </b-tab>
            </b-tabs>
          </b-card>

          <b-card header="Sites de buscas">
            <b-card-text>
              <p class="mb-0">
                Caso queira personalizar o título, descrição e url para
                mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar
                SEO do site" e preencha os campos abaixo.
              </p>
            </b-card-text>
            <b-card-text class="mt-3">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Título SEO</label>
                    <input
                      v-model="item.title_seo"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>URL do concurso</label>
                    <b-input-group
                      :prepend="`/concursos/`"
                      class="input-group-merge"
                    >
                      <b-form-input
                        class="text-primary pl-1"
                        v-model="item.slug"
                      />
                    </b-input-group>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Descrição para SEO</label>
                    <textarea
                      v-model="item.description_seo"
                      maxlength="320"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card header="Imagem de destaque" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    class="form-control-file hidden"
                    @change="previewImage"
                  />
                  <b-form-group>
                    <label
                      for="image"
                      class="d-block text-center"
                      style="cursor: pointer; font-size: 1.1rem"
                    >
                      <div v-if="!preview" class="pt-5 py-4">
                        <feather-icon size="19" icon="ImageIcon" />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img
                          for="image"
                          :src="preview"
                          style="width: 100%; height: 200px"
                          fluid
                        />
                      </template>
                    </label>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Vídeo de apresentação</label>
                    <b-form-input
                      type="text"
                      placeholder="Link do YouTube/Vimeo"
                      v-model="item.video_url"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Label da imagem
                  </label>
                  <b-form-group class="mb-0">
                    <b-form-input
                      v-model="item.label_image"
                      type="text"
                      :class="{ 'is-invalid': $v.item.label_image.$error }"
                    />
                  </b-form-group>
                  <small class="d-block" style="margin-top: 4px">
                    Será exibido sobre a imagem de destaque.
                  </small>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card header="Detalhes" header-class="mb-1" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-12 col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Ficha técnica
                  </label>
                  <b-form-group>
                    <v-select
                      ref="selectDatasheet"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.datasheet"
                      placeholder="Digite o título"
                      :options="optionsDatasheets"
                      @search="fetchDatasheets"
                      :class="{ 'is-invalid': $v.item.datasheet.$error }"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectDatasheet.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-12 col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Área(s)
                  </label>
                  <b-form-group>
                    <v-select
                      multiple
                      ref="selectAreas"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.areas"
                      placeholder="Digite o título"
                      :options="optionsAreas"
                      @search="fetchAreas"
                      :class="{ 'is-invalid': $v.item.areas.$error }"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectAreas.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-12 col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Tag(s)
                  </label>
                  <div class="form-group mb-0">
                    <b-form-tags
                      v-model="item.tags"
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                      :class="{ 'is-invalid': $v.item.tags.$error }"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card header="Depoimento(s)" class="mb-1">
            <b-card-text>
              <v-select
                multiple
                ref="selectApproveds"
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.approveds"
                placeholder="Digite o título"
                :options="optionsApproveds"
                @search="fetchApproveds"
              >
                <span
                  slot="no-options"
                  @click="$refs.selectApproveds.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-card-text>
          </b-card>

          <b-card header="Formulário de interesse" class="mb-1">
            <p class="mb-2">Selecione abaixo, as modalidades que serão exibidas no formulário:</p>
            <div class="form-group">
              <b-form-checkbox v-model="item.form_presencial" class="custom-control-primary">
                Presencial
              </b-form-checkbox>
            </div>

            <div class="form-group">
              <b-form-checkbox v-model="item.form_online" class="custom-control-primary">
                Online
              </b-form-checkbox>
            </div>

            <div class="form-group mb-0">
              <b-form-checkbox v-model="item.form_live" class="custom-control-primary">
                Live
              </b-form-checkbox>
            </div>              
          </b-card>

          <b-card header="Opções" class="mb-1">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.display_home"
                      class="custom-control-primary"
                    >
                      Exibir concurso na pág. inicial
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.display_passport"
                      class="custom-control-primary"
                    >
                      Exibir passaporte
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_whatsapp"
                      class="custom-control-primary"
                    >
                      Desativar botão do WhatsApp
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <b-form-checkbox
                      v-model="item.display_subscription"
                      class="custom-control-primary"
                    >
                      Exibir assinatura
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <hr />
                  <label> Status </label>
                  <v-select
                    placeholder=""
                    v-model="item.status"
                    label="title"
                    item-text="title"
                    item-value="code"
                    :options="[
                      { title: 'Ativo', code: 1 },
                      { title: 'Inativo', code: 0 },
                    ]"
                  />
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <ButtonsActionsFooter
            routerBack="contests-list"
            variant="success"
            :submited="submited"
            text="Cadastrar"
            subtext="Aguarde..."
          />
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="modal-product"
      centered
      title="Vincular Produto"
      cancel-title="Fechar"
      hide-footer
    >
      <b-form>
        <b-card-text>
          <b-row class="form-row">
            <b-col md="12">
              <b-form-group>
                <label> Digite o título do produto </label>
                <v-select
                  ref="selectProduct"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="product"
                  placeholder="Digite o título"
                  :options="optionsProducts"
                  @search="fetchProducts"
                  @input="productSelected"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectProduct.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <div class="col-12" style="min-height: 250px">
              <p>Produtos selecionados:</p>
              <draggable
                v-if="productTypeId === 1"
                tag="ul"
                :list="item.products.presencial"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.presencial"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(1, idx)"
                  />
                </li>
              </draggable>

              <draggable
                v-if="productTypeId === 2"
                tag="ul"
                :list="item.products.online"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.online"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(2, idx)"
                  />
                </li>
              </draggable>

              <draggable
                v-if="productTypeId === 3"
                tag="ul"
                :list="item.products.live"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.live"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(3, idx)"
                  />
                </li>
              </draggable>

              <draggable
                v-if="productTypeId === 6"
                tag="ul"
                :list="item.products.book"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, idx) in item.products.book"
                  :key="element.code"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(6, idx)"
                  />
                </li>
              </draggable>
            </div>
          </b-row>
        </b-card-text>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BTabs,
  BTab,
  BImg,
  BCard,
  BFormTags,
  VBTooltip,
  BInputGroup,
  BCollapse,
  VBToggle,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "swiper/css/swiper.css";
import { mapState } from "vuex";

import Editor from "@tinymce/tinymce-vue";

import { required, requiredIf } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { stringToSlug } from "@/_helpers/_urlHelper";
import { Money } from "v-money";

export default {
  components: {
    BCard,
    ButtonsActionsFooter,
    BFormDatepicker,
    BFormCheckbox,
    Money,
    editor: Editor,
    BCollapse,
    BImg,
    BInputGroup,
    BCardText,
    BTabs,
    BTab,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BFormTags,
    BButton,
  },
  order: 1,
  data() {
    return {
      submited: false,
      product: "",
      productTypeId: 2,
      isPresencial: true,
      isLive: true,
      isHour: true,
      isCall: true,
      user: {},
      organs: undefined,
      educations: undefined,
      filteredOptions: [],
      optionsSalesForce: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      selectPosition: "",
      item: {
        title: "",
        description: "",
        about: "",
        slug: "",
        video_url: "",
        label_image: "",
        title_seo: "",
        description_seo: "",
        datasheet: "",
        approveds: [],
        tags: [],
        form_presencial: false,
        form_online: false,
        form_live: false,
        display_home: false,
        sales_force_positions: false,
        display_passport: false,
        display_subscription: false,
        disable_whatsapp: false,
        status: { title: "Ativo", code: 1 },
        products: {
          online: [],
          presencial: [],
          live: [],
          book: [],
        },
        salesforce: "",
        positions: [],
        areas: [],
      },
      listProductsPresencial: [],
      listProductsBook: [],
      listProductsLive: [],
      listOffice: [],
      dragging: false,
      preview: null,
      image: null,
      optionsDatasheets: [],
      optionsApproveds: [],
      optionsProducts: [],
      optionsSalesForce: [],
      optionsSalesForcePositions: [],
      optionsAreas: [],
    };
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.$http.get("/autosuggest/data").then((res) => {
      this.datasuggest = res;
    });
  },
  watch: {
    "item.slug": {
      handler() {
        this.item.slug = stringToSlug(this.item.slug);
      },
    },
  },
  methods: {
    removeAtPosition(idx) {
      this.item.positions.splice(idx, 1);
    },
    positionSelected (e) {
      this.selectPosition = ''
      this.item.positions.push(e)
    },
    async fetchSalesForcePositions(term) {
      if (term.length > 2) {
        this.$store.dispatch("SalesForce/search", term).then((response) => {
          this.optionsSalesForcePositions = response;
        });
      }
    },
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    async fetchSalesForce(term) {
      if (term.length > 2) {
        this.$store.dispatch("SalesForce/search", term).then((response) => {
          this.optionsSalesForce = response;
        });
      }
    },
    productSelected(event) {
      if (event) {
        this.optionsProducts = "";
        this.product = "";

        switch (event.product_category_id) {
          case 1:
            if (
              !this.item.products.presencial.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.presencial.push({
                code: event.code,
                title: event.title,
              });
            }
            break;
          case 2:
            if (
              !this.item.products.online.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.online.push({
                code: event.code,
                title: event.title,
              });
            }

            break;
          case 3:
            if (
              !this.item.products.live.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.live.push({
                code: event.code,
                title: event.title,
              });
            }
            break;
          default:
            if (
              !this.item.products.book.find(
                (element) => element.code === event.code
              )
            ) {
              this.item.products.book.push({
                code: event.code,
                title: event.title,
              });
            }
            break;
        }
      }
    },
    changeProductType(event) {
      this.productTypeId = event;
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/search", { term: term, type: this.productTypeId })
          .then((res) => {
            this.optionsProducts = res;
          });
      }
    },
    async fetchDatasheets(term) {
      if (term.length > 1) {
        this.optionsDatasheets = await this.$store.dispatch(
          "Datasheet/search",
          term
        );
      }
    },
    async fetchApproveds(term) {
      if (term.length > 1) {
        this.$store.dispatch("Approved/search", term).then(() => {
          this.optionsApproveds = this.searchApproveds;
        });
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    reset() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    removeAt(type, idx) {
      switch (type) {
        case 1:
          delete this.item.products.presencial[idx];
          break;
        case 2:
          delete this.item.products.online[idx];
          break;
        case 3:
          delete this.item.products.live[idx];
          break;
        default:
          delete this.item.products.book[idx];
          break;
      }

      this.item.products.book.splice(idx, 1);
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      const filteredDevelopers = this.datasuggest.data[0].developers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredDesigner = this.datasuggest.data[0].designers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredData = filteredDevelopers.concat(filteredDesigner);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    productType(value) {
      this.item.type = value;
    },
    submitCreate() {
      this.$v.$touch();
      
      if (!this.$v.$error) {
        this.submited = true;

        const formData = new FormData();
        formData.append("image", this.item.image);
        formData.append("title", this.item.title);
        formData.append("title_seo", this.item.title_seo);
        formData.append("description", this.item.description);
        formData.append("about", this.item.about);
        formData.append("description_seo", this.item.description_seo);
        formData.append("label_image", this.item.label_image);
        formData.append("video_url", this.item.video_url ?? "");
        formData.append(
          "datasheet",
          this.item.datasheet ? this.item.datasheet.code : ""
        );
        formData.append("approveds", JSON.stringify(this.item.approveds));
        formData.append(
          "display_subscription",
          this.item.display_subscription ? 1 : 0
        );
        formData.append("disable_whatsapp", this.item.disable_whatsapp ? 1 : 0);

        formData.append("display_passport", this.item.display_passport ? 1 : 0);
        formData.append("display_home", this.item.display_home ? 1 : 0);

        formData.append("form_presencial", this.item.form_presencial ? 1 : 0);
        formData.append("form_online", this.item.form_online ? 1 : 0);
        formData.append("form_live", this.item.form_live ? 1 : 0);

        formData.append("sales_force_positions", this.item.sales_force_positions ? 1 : 0);
        formData.append("positions", this.item.positions ? JSON.stringify(this.item.positions) : '');
        formData.append("tags", JSON.stringify(this.item.tags));
        formData.append(
          "status",
          this.item.status ? this.item.status.code : ""
        );
        formData.append("slug", this.item.slug);
        formData.append("products", JSON.stringify(this.item.products));
        formData.append("areas", JSON.stringify(this.item.areas));
        formData.append(
          "salesforce",
          this.item.salesforce && !this.item.sales_force_positions ? this.item.salesforce.code : ""
        );

        this.$store.dispatch("Contest/create", formData).then(() => {
          this.notifyDefault("success");
          this.$router.push({ name: "contests-list" });
        }).catch(() => {
          this.submited = false;  
        });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  validations: {
    item: {
      title: {
        required,
      },
      description: {
        required,
      },
      label_image: {
        required,
      },
      datasheet: {
        required,
      },
      tags: {
        required,
      },
      status: {
        required,
      },
      salesforce: {
        required: requiredIf((vm) => {
          return vm.sales_force_positions ? false : true
        })
      },
      positions: {
        required: requiredIf((vm) => {
          return vm.sales_force_positions ? true : false
        })
      },
      areas: {
        required,
      },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  computed: {
    ...mapState("Area", ["searchAreas"]),
    ...mapState("Approved", ["searchApproveds"]),
    ...mapState("Product", ["searchProductsPresencial", "searchProductsLive"]),
  },
};
</script>

<style>
.b-form-datepicker label {
  line-height: 0;
}
.subtitle-quill .ql-container .ql-editor,
.subtitle-quill .ql-container {
  height: 100px !important;
  min-height: 100px !important;
}
</style>
