var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreate.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "form-row" },
            [
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Informações básicas" },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [
                                        _c("i", {
                                          staticClass:
                                            "text-danger bi bi-record-circle",
                                        }),
                                        _vm._v(" Título "),
                                      ]),
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.title.$error,
                                        },
                                        attrs: {
                                          placeholder: "Título do produto",
                                        },
                                        model: {
                                          value: _vm.item.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "title", $$v)
                                          },
                                          expression: "item.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-1",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.description.$error,
                                      },
                                    },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _c("i", {
                                          staticClass:
                                            "text-danger bi bi-record-circle",
                                        }),
                                        _vm._v(" Descrição "),
                                      ]),
                                      _c("editor", {
                                        staticClass: "about-editor-1",
                                        attrs: {
                                          "api-key":
                                            "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                          init: this.optionEditor({
                                            height: 600,
                                            selector: "about-editor-1",
                                          }),
                                        },
                                        model: {
                                          value: _vm.item.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "item.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { staticClass: "mb-0 mt-1" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Sobre o produto - "),
                                        _c("small", [
                                          _vm._v(
                                            "Será exibido no final da página."
                                          ),
                                        ]),
                                      ]),
                                      _c("editor", {
                                        staticClass: "about-editor-2",
                                        attrs: {
                                          "api-key":
                                            "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                          init: this.optionEditor({
                                            height: 300,
                                            selector: "about-editor-2",
                                          }),
                                        },
                                        model: {
                                          value: _vm.item.about,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "about", $$v)
                                          },
                                          expression: "item.about",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Sales Force", "header-class": "mb-1" },
                    },
                    [
                      _c("b-card-text", [
                        _c(
                          "div",
                          { staticClass: "form-group mb-2" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "custom-control-primary",
                                on: { change: _vm.changeSalesForcePosition },
                                model: {
                                  value: _vm.item.sales_force_positions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "sales_force_positions",
                                      $$v
                                    )
                                  },
                                  expression: "item.sales_force_positions",
                                },
                              },
                              [_vm._v(" Exibir cargos (Sales Force) ")]
                            ),
                          ],
                          1
                        ),
                        _vm.item.sales_force_positions
                          ? _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Cargos "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    staticClass: "v-select-tags",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.positions.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsSalesForcePositions,
                                    },
                                    on: {
                                      search: _vm.fetchSalesForcePositions,
                                      input: _vm.positionSelected,
                                    },
                                    model: {
                                      value: _vm.selectPosition,
                                      callback: function ($$v) {
                                        _vm.selectPosition = $$v
                                      },
                                      expression: "selectPosition",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                                _vm.item.positions
                                  ? _c(
                                      "draggable",
                                      {
                                        staticClass: "list-group mt-1",
                                        attrs: {
                                          tag: "ul",
                                          list: _vm.item.positions,
                                          handle: ".handle",
                                        },
                                      },
                                      _vm._l(
                                        _vm.item.positions,
                                        function (element, idx) {
                                          return _c(
                                            "li",
                                            {
                                              key: element.code,
                                              staticClass: "list-group-item",
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "handle",
                                                attrs: {
                                                  icon: "MoveIcon",
                                                  size: "15",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pl-1 text-not-selected",
                                                },
                                                [_vm._v(_vm._s(element.title))]
                                              ),
                                              _c("feather-icon", {
                                                staticClass: "float-right",
                                                attrs: {
                                                  icon: "XCircleIcon",
                                                  size: "20",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeAtPosition(
                                                      idx
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _c("p", { staticClass: "mt-1 mb-0" }, [
                                  _vm._v(
                                    "Os cargos serão listados no formulário de interesse, na página do concurso."
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Sales force "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    ref: "selectSalesForce",
                                    staticClass: "text-elipse-300",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.salesforce.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsSalesForce,
                                    },
                                    on: { search: _vm.fetchSalesForce },
                                    model: {
                                      value: _vm.item.salesforce,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "salesforce", $$v)
                                      },
                                      expression: "item.salesforce",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.selectSalesForce.open = false
                                          },
                                        },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: {
                        header: "Produtos relacionados",
                        "header-class": "mb-1",
                      },
                    },
                    [
                      _c(
                        "b-tabs",
                        [
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Curso Online" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(2)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.online,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.online,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(2, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Curso Presencial" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(1)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.presencial,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.presencial,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(1, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Live" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(3)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.live,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.live,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(3, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: "Apostilas" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeProductType(6)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-md-9" }, [
                                  _c("p", { staticClass: "mb-0 pt-1" }, [
                                    _vm._v(
                                      " Você pode ordenar arrastando os produtos listados abaixo. "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-product",
                                            modifiers: {
                                              "modal-product": true,
                                            },
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(113, 102, 240, 0.15)",
                                            expression:
                                              "'rgba(113, 102, 240, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          block: "",
                                          variant: "flat-primary",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PlusCircleIcon" },
                                        }),
                                        _vm._v(" adicionar "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: this.item.products.book,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  this.item.products.book,
                                  function (element, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(6, index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { attrs: { header: "Sites de buscas" } },
                    [
                      _c("b-card-text", [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            ' Caso queira personalizar o título, descrição e url para mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar SEO do site" e preencha os campos abaixo. '
                          ),
                        ]),
                      ]),
                      _c("b-card-text", { staticClass: "mt-3" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Título SEO")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.title_seo,
                                    expression: "item.title_seo",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.item.title_seo },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "title_seo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [_vm._v("URL do concurso")]),
                                _c(
                                  "b-input-group",
                                  {
                                    staticClass: "input-group-merge",
                                    attrs: { prepend: "/concursos/" },
                                  },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "text-primary pl-1",
                                      model: {
                                        value: _vm.item.slug,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "slug", $$v)
                                        },
                                        expression: "item.slug",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Descrição para SEO")]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.description_seo,
                                    expression: "item.description_seo",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { maxlength: "320" },
                                domProps: { value: _vm.item.description_seo },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "description_seo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Imagem de destaque" },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            { staticClass: "form-row" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("input", {
                                    staticClass: "form-control-file hidden",
                                    attrs: {
                                      id: "image",
                                      type: "file",
                                      accept: "image/*",
                                    },
                                    on: { change: _vm.previewImage },
                                  }),
                                  _c("b-form-group", [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "d-block text-center",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "font-size": "1.1rem",
                                        },
                                        attrs: { for: "image" },
                                      },
                                      [
                                        !_vm.preview
                                          ? _c(
                                              "div",
                                              { staticClass: "pt-5 py-4" },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    size: "19",
                                                    icon: "ImageIcon",
                                                  },
                                                }),
                                                _vm._v(" Selecionar imagem "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.preview
                                          ? [
                                              _c("b-img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "200px",
                                                },
                                                attrs: {
                                                  for: "image",
                                                  src: _vm.preview,
                                                  fluid: "",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [
                                        _vm._v("Vídeo de apresentação"),
                                      ]),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder: "Link do YouTube/Vimeo",
                                        },
                                        model: {
                                          value: _vm.item.video_url,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "video_url", $$v)
                                          },
                                          expression: "item.video_url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("label", [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Label da imagem "),
                                  ]),
                                  _c(
                                    "b-form-group",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.label_image.$error,
                                        },
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.item.label_image,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.item,
                                              "label_image",
                                              $$v
                                            )
                                          },
                                          expression: "item.label_image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "small",
                                    {
                                      staticClass: "d-block",
                                      staticStyle: { "margin-top": "4px" },
                                    },
                                    [
                                      _vm._v(
                                        " Será exibido sobre a imagem de destaque. "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Detalhes", "header-class": "mb-1" },
                    },
                    [
                      _c("b-card-text", [
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Ficha técnica "),
                              ]),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectDatasheet",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.datasheet.$error,
                                      },
                                      attrs: {
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Digite o título",
                                        options: _vm.optionsDatasheets,
                                      },
                                      on: { search: _vm.fetchDatasheets },
                                      model: {
                                        value: _vm.item.datasheet,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "datasheet", $$v)
                                        },
                                        expression: "item.datasheet",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectDatasheet.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Área(s) "),
                              ]),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectAreas",
                                      class: {
                                        "is-invalid": _vm.$v.item.areas.$error,
                                      },
                                      attrs: {
                                        multiple: "",
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Digite o título",
                                        options: _vm.optionsAreas,
                                      },
                                      on: { search: _vm.fetchAreas },
                                      model: {
                                        value: _vm.item.areas,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "areas", $$v)
                                        },
                                        expression: "item.areas",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectAreas.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-12 col-12" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Tag(s) "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c("b-form-tags", {
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.$v.item.tags.$error,
                                  },
                                  attrs: {
                                    separator: ",;",
                                    "no-add-on-enter": "",
                                    placeholder: "",
                                  },
                                  model: {
                                    value: _vm.item.tags,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "tags", $$v)
                                    },
                                    expression: "item.tags",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Depoimento(s)" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "v-select",
                            {
                              ref: "selectApproveds",
                              attrs: {
                                multiple: "",
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.optionsApproveds,
                              },
                              on: { search: _vm.fetchApproveds },
                              model: {
                                value: _vm.item.approveds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "approveds", $$v)
                                },
                                expression: "item.approveds",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectApproveds.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Formulário de interesse" },
                    },
                    [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          "Selecione abaixo, as modalidades que serão exibidas no formulário:"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "custom-control-primary",
                              model: {
                                value: _vm.item.form_presencial,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "form_presencial", $$v)
                                },
                                expression: "item.form_presencial",
                              },
                            },
                            [_vm._v(" Presencial ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "custom-control-primary",
                              model: {
                                value: _vm.item.form_online,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "form_online", $$v)
                                },
                                expression: "item.form_online",
                              },
                            },
                            [_vm._v(" Online ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group mb-0" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "custom-control-primary",
                              model: {
                                value: _vm.item.form_live,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "form_live", $$v)
                                },
                                expression: "item.form_live",
                              },
                            },
                            [_vm._v(" Live ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Opções" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "custom-control-primary",
                                          model: {
                                            value: _vm.item.display_home,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "display_home",
                                                $$v
                                              )
                                            },
                                            expression: "item.display_home",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Exibir concurso na pág. inicial "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "custom-control-primary",
                                          model: {
                                            value: _vm.item.display_passport,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "display_passport",
                                                $$v
                                              )
                                            },
                                            expression: "item.display_passport",
                                          },
                                        },
                                        [_vm._v(" Exibir passaporte ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "custom-control-primary",
                                          model: {
                                            value: _vm.item.disable_whatsapp,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "disable_whatsapp",
                                                $$v
                                              )
                                            },
                                            expression: "item.disable_whatsapp",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Desativar botão do WhatsApp "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { staticClass: "mb-0" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "custom-control-primary",
                                          model: {
                                            value:
                                              _vm.item.display_subscription,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "display_subscription",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.display_subscription",
                                          },
                                        },
                                        [_vm._v(" Exibir assinatura ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("hr"),
                                  _c("label", [_vm._v(" Status ")]),
                                  _c("v-select", {
                                    attrs: {
                                      placeholder: "",
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      options: [
                                        { title: "Ativo", code: 1 },
                                        { title: "Inativo", code: 0 },
                                      ],
                                    },
                                    model: {
                                      value: _vm.item.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "status", $$v)
                                      },
                                      expression: "item.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ButtonsActionsFooter", {
                    attrs: {
                      routerBack: "contests-list",
                      variant: "success",
                      submited: _vm.submited,
                      text: "Cadastrar",
                      subtext: "Aguarde...",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-product",
            centered: "",
            title: "Vincular Produto",
            "cancel-title": "Fechar",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-card-text",
                [
                  _c(
                    "b-row",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(" Digite o título do produto "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectProduct",
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsProducts,
                                  },
                                  on: {
                                    search: _vm.fetchProducts,
                                    input: _vm.productSelected,
                                  },
                                  model: {
                                    value: _vm.product,
                                    callback: function ($$v) {
                                      _vm.product = $$v
                                    },
                                    expression: "product",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectProduct.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          staticStyle: { "min-height": "250px" },
                        },
                        [
                          _c("p", [_vm._v("Produtos selecionados:")]),
                          _vm.productTypeId === 1
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.presencial,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.presencial,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(1, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.productTypeId === 2
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.online,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.online,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(2, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.productTypeId === 3
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.live,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.live,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(3, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.productTypeId === 6
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "list-group mt-1",
                                  attrs: {
                                    tag: "ul",
                                    list: _vm.item.products.book,
                                    handle: ".handle",
                                  },
                                },
                                _vm._l(
                                  _vm.item.products.book,
                                  function (element, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: element.code,
                                        staticClass: "list-group-item",
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "handle",
                                          attrs: {
                                            icon: "MoveIcon",
                                            size: "15",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-1 text-not-selected",
                                          },
                                          [_vm._v(_vm._s(element.title) + " ")]
                                        ),
                                        _c("feather-icon", {
                                          staticClass: "float-right",
                                          attrs: {
                                            icon: "XCircleIcon",
                                            size: "20",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(6, idx)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }